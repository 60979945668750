import { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import isEmpty from 'lodash/isEmpty'
import { Navigate, useLocation } from 'react-router-dom'
import { localStorage } from 'src/ulils/localstorage'
import { getParsetQuery } from '../ulils/parseQuery'
import { useGetUserInfoQuery } from 'src/redux/API/User/Auth'

export const Autorized = ({ children }: { children: JSX.Element }) => {
  const { search, pathname } = useLocation()
  const token = localStorage({ key: 'token' }).getItem()
  const { pid } = getParsetQuery(search) ?? {}
  const user = useGetUserInfoQuery({})

  if (isEmpty(token)) {
    return <Navigate to='/auth' state={{ pid, pathname }} />
  }

  if (!user.data) return null

  return (
    <Fragment>
      <Helmet>
        {/*<script*/}
        {/*  src="https://miro.com/app/board/uXjVN4rfXwU=/?moveToWidget=3458764597658991768&cot=14"*/}
        {/*></script>*/}

        <script>
          {`
            window.embeddedChatbotConfig = {
              chatbotId: "7O5hrheTMTimpFsRq2KQm",
              domain: "www.chatbase.co"
            };
          `}
        </script>

        <script
          src='https://www.chatbase.co/embed.min.js'
          chatbotId='7O5hrheTMTimpFsRq2KQm'
          domain='www.chatbase.co'
          defer
        ></script>
      </Helmet>

      {/*<iframe*/}
      {/*  src='https://www.chatbase.co/chatbot-iframe/7O5hrheTMTimpFsRq2KQm'*/}
      {/*  width='100%'*/}
      {/*  style={{ height: '100%', minHeight: '700px' }}*/}
      {/*  frameBorder='0'*/}
      {/*></iframe>*/}

      {children}
    </Fragment>
  )
}
